<template>
  <v-dialog
    v-model="isDeleteModalOpen"
    persistent
    max-width="400px"
  >
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("stockAdjustments.delete_adjustment") }}</v-card-title
      >
      <v-card-text class="text-h5">
        {{ $t("stockAdjustments.delete_confirmation_message") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="close()"
          color="indigo"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm()"
          text
        >
          {{ $t("stockAdjustments.delete_adjustment") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isDeleteModalOpen: Boolean,
    toggleDeleteModal: Function,
    stockAdjustment: Object,
  },

  data() {
    return { isLoading: false };
  },
  methods: {
    close() {
      this.toggleDeleteModal();
    },
    async confirm() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "stockAdjustments/destroy",
          this.stockAdjustment
        );

        this.toggleDeleteModal();
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>